<style>
    body {
      font-family: 'Raleway', sans-serif;
      margin: 0;
      padding: 20px;
      background-color: #222;
      color: #fff;
      backdrop-filter: blur(10px);
      overflow: hidden;
      height: 100vh;
    }


    h1 {
      text-align: center;
      font-weight: 900;
      text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
      font-size: 64px;
    }

    h2 {
      text-align: center;
      font-weight: bold;
      text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
      font-size: 20px;
    }

    form {
      margin-bottom: 20px;
      display: flex;
      justify-content: center;
      /* Center items horizontally */
      align-items: center;
      /* Center items vertically */
      bottom: 12px;
      width: 100vw;
      border-radius: 10px;
      padding: 10px;
      /* Add some padding to give space around the items */
    }

    label {
      font-weight: bold;
      color: #ccc;
    }

    input[type="text"] {
      width: 50%;
      padding: 10px;
      background-color: rgba(0, 0, 0, 0.3);
      color: #fff;
      border: none;
      border-radius: 5px;
    }

    textarea {
      width: 50%;
      padding: 9px;
      color: whitesmoke;

      border: none;
      border-radius: 5px;
      font-size: 18px;
      background-color: #222222;

    }

    textarea::-webkit-scrollbar {
      display: none;
    }

    textarea:focus {
      outline: none;
    }


    #response {
      margin-top: 20px;
      max-width: 80%;
      height: 800px;
      margin-left: 10%;
      overflow-y: scroll;
    }

    #response::-webkit-scrollbar {
      width: 10px;
      border-radius: 50%;
    }


    .user-message {
      background-color: 	#7393B3;
      padding: 10px;
      margin-bottom: 10px;
      border-radius: 10px;
      backdrop-filter: blur(4px);
      -webkit-backdrop-filter: blur(4px);
      border-radius: 10px;
      border: 1px solid rgba(255, 255, 255, 0.18);
      color: whitesmoke;
    }

    .ai-message {
      background-color: 	#818589;
      padding: 10px;
      margin-bottom: 10px;
      border-radius: 10px;
      color: whitesmoke;
    }

    p {
      margin: 0;
      padding: 0;
    }

    .smaller {
      font-size: 15;
    }

    .fa-github {
      text-decoration: none;
      color: inherit;
      position: fixed;
      bottom: 0;
      right: 0;
      padding: 15px;
    }

    .dropbtn {
      background-color: #191b1c;
      color: white;
      padding: 16px;
      font-size: 16px;
      border: none;
      cursor: pointer;
    }

    .dropdown {
      position: relative;
      display: inline-block;
      position: absolute;
      top: 5px;
      left: 5px;
      padding: 10px;
      color: #fff;
      border: none;
      border-radius: 5px;
      cursor: pointer;
    }

    .dropdown-content {
      display: none;
      position: absolute;
      background-color: #191b1c;
      min-width: 160px;
      box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
      z-index: 1;
    }

    .dropdown-content a {
      color: #fff;
      padding: 12px 16px;
      text-decoration: none;
      display: block;
    }

    .dropdown-content a:hover {
      background-color: black
    }

    .dropdown:hover .dropdown-content {
      display: block;
    }

    .dropdown:hover .dropbtn {
      background-color: #444;
    }

    button {
      font-size: 18px;
      color: #e1e1e1;
      font-family: inherit;
      font-weight: 800;
      cursor: pointer;
      position: relative;
      border: none;
      background: none;
      text-transform: uppercase;
      transition-timing-function: cubic-bezier(0.25, 0.8, 0.25, 1);
      transition-duration: 400ms;
      transition-property: color;
    }

    button:focus,
    button:hover {
      color: #fff;
    }

    button:focus:after,
    button:hover:after {
      width: 100%;
      left: 0%;
    }

    button:after {
      content: "";
      pointer-events: none;
      bottom: -2px;
      left: 50%;
      position: absolute;
      width: 0%;
      height: 2px;
      background-color: #fff;
      transition-timing-function: cubic-bezier(0.25, 0.8, 0.25, 1);
      transition-duration: 400ms;
      transition-property: width, left;
    }

    .github-icon {
      position: fixed;
      right: 50px;
      bottom: 50px;
    }

    /* if screen width<1000 then max -width=100% */
    @media screen and (max-width: 1000px) {
      #response {
        max-width: 100%;
        margin-left: 0;
        height:800px;
      }

      textarea {
        width: 80%;
      }

      .github-icon {
        visibility: hidden;
      }

      form {
        bottom: 0;
      }

      .ai-message {
        max-width: 80%;
        min-width: 80%;
      }
    }